import { BaseElement, html, css } from 'Components';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';
import './CookieConsent.js';
import Logo from '../../Logo.svg.js';

class Footer extends BaseElement {
  static get styles() {
    return css`
      :host {
        background-color: var(--nsys-blue5);
        color: var(--nsys-white);
        display:block;
        border-top:1px solid var(--nsys-blue3s);
        box-shadow: rgba(0, 0, 0, 0.3) 0px -5px 5px 0px;
        page-break-inside: avoid;
      }

      .footer svg {
        width: 25vw;
        min-width: 300px;
      }

      .footer {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-top: 20px;
        padding-bottom: 70px;
        text-align: center;
        line-height:20px;
      }

      .grid {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 20px;
        margin: 20px;
        width:80vw;
      }

      .grid div {
        text-align:left;
      }

      a-route::part(text) {
        color: white !important;
        text-decoration: none;
      }

      /* iphone 8 */
      @media (max-width: 670px) {
        .footer svg {
          width: 25vw;
          min-width: 200px;
        }

        .footer {
          font-size: 0.8em;
        }

        .grid {
          grid-template-columns: repeat(2, 1fr);
        }
      }

      @media print {
        :host {
          position: relative;
          box-shadow: initial;
          background-color:transparent;
          display:none;
        }
      
        .footer svg .st0 {
          fill: #495154;
        }

      }

    `;
  }
  
  render() {
    return html`
      <div class="footer">
        ${Logo}
        <slot></slot>
      </div>
      <cookie-consent></cookie-consent>
      ${ (ENV === 'dev') ? unsafeHTML('<debug-screen-size></debug-screen-size>') : ''}
    `;
  }
}

customElements.define('app-footer', Footer);