import {  BaseElement, css } from 'Components';

class ContextMenu extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display: none;
          position: fixed;
          border: var(--context-menu-border);
          border-radius: 0px;
          box-shadow: var(--context-menu-box-shadow);
          z-index: 1000;
        }

        sl-menu {
          padding:0;
          margin:0;
          border-radius:0px;
        }

        sl-menu-item {
          padding: 0;
          margin:2px;
          cursor: pointer;
        }

        sl-menu-item::part(label) {
          padding:0px;
          font-size:0.9em;
          font-family: Calibri;
          line-height:initial;
        }

        m-icon {
          position:absolute;
          left:7px;
        }

        sl-menu-item.delete::part(base) {
          color:var(--sl-color-danger-600);
        }

        sl-menu-item:hover {
          background-color: #f0f0f0;
        }

        sl-divider {
          margin: 2px;
        }
      `
    ];
  }

  static get properties() {
    return {
      content: { type: String },
    };
  }

  constructor() {
    super();
    this.debug = false;
    this.onClick = this.onClick.bind(this);
  }

  connectedCallback() {
    super.connectedCallback();
    this.container = this.getContainer();
    document.body.addEventListener('click', this.onClick);
  }

  getContainer() {
    let c = document.body.querySelector('context-menu-container');
    if (!c) {
      this._log.debug('creating context-menu-container');
      c = document.createElement('context-menu-container');
      c.className = 'context-menu';
      document.body.appendChild(c);
    }
    return c;
  }

  onClick() {
    this._log.debug('onClick');
    this.style.display = 'none';
  }


  reposition() {
    if (!this.style) return;
    const rect = this.getBoundingClientRect();
    if (rect.left + rect.width > window.innerWidth) {
      this.style.left = `${window.innerWidth - rect.width - 50}px`;
    }
  }

  show(x, y) {
    this._log.debug('show', x,y);
    this.style.visibility = 'hidden';
    this.style.display = 'block';
    this.style.left = `${x}px`;
    this.style.top = `${y}px`;

    setTimeout(() => {
      this.reposition();
      this.style.visibility = 'visible';
    }, 1);

  }

  render() {
    this._log.debug('render', this.content);
    if (!this.content) {
      return '';
    }
    return this.content;
  }
}

customElements.define('context-menu', ContextMenu);

