import { LitElement, html, css } from 'lit-element';

class MaterialTag extends LitElement {
  static get properties() {
    return {
      tag: { type: Object },
      size: { type: String },
      variant: { type: String }
    }
  }

  static get styles() {
    return css`
      .small {
        font-size:0.8em;
      }

      .big {
        font-size:1.8em;
      }

      sl-tag.product::part(base) {
        background-color: var(--sl-color-fuchsia-50);
        border-color: var(--sl-color-fuchsia-200);
        color: var(--sl-color-fuchsia-800);
      }

      sl-tag.component::part(base) {
        background-color: var(--sl-color-blue-50);
        border-color: var(--sl-color-blue-200);
        color: var(--sl-color-blue-800);
      }

    `;
  }

  constructor() {
    super();
    this.tag = null;
    this.size = 'medium';
  }

  getVariant() {
    if (this.tag) {
      if (this.tag.filter === 'product') {
        return 'primary';
      } else if (this.tag.filter === 'component') {
        return 'danger';
      }
    }
    return '';
  }

  firstUpdated() {
    console.log('firstUpdated');
  }

  render() {
    return html`
      <sl-tag 
        variant="${this.getVariant()}" 
        size="${this.size}"
      ><slot></slot></sl-tag>
    `;
  }

}

customElements.define('m-tag', MaterialTag);