import { LitElement } from 'lit-element';

import './PrettyJson.js';

class BoxJson extends LitElement {
  static get properties() {
    return {
      expand: { type: String },
    };
  }

  constructor() {
    super();
    this.expand = 4;
  }

  firstUpdated() {
    super.firstUpdated();

    let style = '';
    style+='font-size:0.9em !important;';

    this.innerHTML = `
      <div style="padding:10px;background-color:white;">
        <style>.container { font-size:initial; }</style>
        <pretty-json 
          expand="${this.expand}"
          style="${style}"
        >${this.innerHTML}</pretty-json>
      </div>
    `;
  }

  createRenderRoot() {
    return this;
  }
}

customElements.define('box-json', BoxJson);


