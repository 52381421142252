import { BaseElement, html, css } from 'Components';
import App from 'App';

class MaterialIconButton extends BaseElement {
  static get properties() {
    return {
      name: { type: String },
      clickable: { type: Boolean },
      hidden: { type: Boolean },
      gradient: { type: Boolean },
      aroute: { type: String },
      size: { type: String },
      color: { type: String }
    }
  }

  static get styles() {
    return css`
      :host {
        font-family: "Material Symbols Sharp";
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        white-space: nowrap;
        word-wrap: normal;
        direction: ltr;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        font-feature-settings: "liga";
        user-select:none;
        transition:opacity 0.3s;
      }

      .gradient {
        background: linear-gradient(60deg,#1d6393, #1fbbeb);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }

      :host(.clickable) {
        cursor: pointer;
      }

      :host(.hidden) {
        opacity:0
      }

      .small {
        font-size:0.8em;
      }

      .big {
        font-size:1.8em;
      }

    `;
  }

  constructor() {
    super();
    this.name = '&nbsp;';
    this.aroute = '';
    this.clickable = false;
    this.gradient = App.config.miconGradient;
    this.size = '';
    this.color = '';
  }

  render() {
    let style = '';
    if (this.color) { 
      style+=`color:${this.color} !important;`;
    }

    if (this.aroute) {
      return html`<a-route href="${this.aroute}"><span class="${this.size} ${this.gradient ? 'gradient' : ''}" ${style}>${this.name}</span></a-route>`;
    } else {
      return html`<span class="${this.size} ${this.gradient ? 'gradient' : ''}" style="${style}">${this.name}</span>`;
    }
  }

  updated(changedProperties) {
    if (changedProperties.has('clickable')) {
      this.clickable ? this.classList.add('clickable') : this.classList.remove('clickable');
    }

    if (changedProperties.has('hidden')) {
      this.hidden ? this.classList.add('hidden') : this.classList.remove('hidden');
    }
  }
}

customElements.define('m-icon', MaterialIconButton);