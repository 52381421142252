import { BaseElement, html, css } from 'Components';

class ImgProxy extends BaseElement {
  static get styles() {
    return [
      css`
        :host {
          display: block;
          position: relative;
          align-content: center;
          padding:0px;
        }

        .container {
          overflow:hidden;
          display:flex;
          border-radius: var(--img-border-radius, 0); /* Utilisation de la variable CSS pour le radius */
        }

        .loading {
        }

        div::after {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color:var(--sl-color-primary-500);
          font-family: "Material Symbols Sharp";
          font-weight: normal;
          font-style: normal;
          font-size: 24px;
          line-height: 1;
          letter-spacing: normal;
          text-transform: none;
          display: inline-block;
          white-space: nowrap;
          word-wrap: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          text-rendering: optimizeLegibility;
          font-feature-settings: "liga";
          user-select:none;
          transition:opacity 2s;
          opacity:1;
        }

        .loading::after {
          /*content: "\\e9d0";*/
          animation: spin 2s linear infinite;
        }

        .loaded::after {
          opacity: 0;
        }

        div img {
          opacity:0;
          transition:opacity 0.5s;
        }

        .loaded img {
          opacity:1;
          transition:opacity 0.5s;
        }

        .errored::after {
          content: "\\e7f3";
          animation: initial;
          color:var(--sl-color-neutral-400);
        }

        .errored {
          border: 1px solid var(--sl-color-neutral-400);
        }

        .errorer img {
          opacity:0;
        }

        @keyframes spin {
          from {
            transform: translate(-50%, -50%) rotate(0deg);
          }
          to {
            transform: translate(-50%, -50%) rotate(360deg);
          }
        }
      `
    ]
  }

  static get properties() {
    return {
      src: { type: String, reflect: true },
      width: { type: Number },
      height: { type: Number },
      ns: { type: String },
      enlarge: { type: Number },
      extend: { type: Number },
      resize: { type: String },
      sharp: { type: Number },
      quality: { type: Number },
      click: { type: Function },
      radius: { type: String },
      hover: { type: String }
    }
  }

  constructor() {
    super();
    this.ns = 'artgoone';
    this.enlarge = 0;
    this.extend = 1;
    this.resize = 'fill'
    this.sharp = 0.7;
    this.quality = 70;
    this.width = 100;
    this.radius = '0';
    this.height = null;
  }

  updated(changedProperties) {
    if (changedProperties.has('radius')) {
      this.style.setProperty('--img-border-radius', this.radius); // Mise à jour de la variable CSS
    }

    if (!this.img) return;
    
    if (changedProperties.has('src')) {
      this.ignoreNextLoad = false;
      this.div.classList.remove('loaded');  
      this.div.classList.add('loading');
      this.img.src = this.getUrl();
    }

  }

  getImageBackgroundColor() {
    let bg = getComputedStyle(document.body).getPropertyValue('--page-background-color').replace('#','');
    if (bg.length > 6) bg = bg.substring(0,6);
    return bg;
  }

  getUrl() {
    
    if (this.src.match(/^http/)) return this.src;

    let url = STATIC_PUBLIC_URL;
    url+=`/imgp/${this.ns}${this.src}@`;
    url+=`/rs:${this.resize}:${this.width}:${this.height || this.width}:${this.enlarge}:${this.extend}`;
    url+=`/sh:${this.sharp}`;
    url+=`/q:${this.quality}`;
    url+=`/bg:${this.getImageBackgroundColor()}`;
    url+='/f:webp';

    return url;
  }
    

  handleImageLoad() {
    if (this.ignoreNextLoad) return;
    
    this.div.classList.remove('loading');
    setTimeout(() => {
      if (!this.div) return;
      this.div.classList.add('loaded');  
    }, 1);
  }

  handleImageError() {
    const pixelTransparent = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';
    this.ignoreNextLoad = true;
    this.img.setAttribute('url', this.img.src);
    this.img.src = pixelTransparent;
    this.div.classList.add('errored');
  }

  visibleCallback() {
    this.img = this.img || this.shadowRoot.querySelector('img');
    this.div = this.div || this.shadowRoot.querySelector('div');
    this.img.src = this.getUrl();
  }

  render() {
    return html`
      <div class="container loading ${this.hover}" style="width:${this.width};height:${this.height}">
        <img 
          width="${this.width}" 
          height="${this.height}"
          @load=${this.handleImageLoad}
          @error=${this.handleImageError}
        />
      </div>
    `;
  }
}

customElements.define('img-proxy', ImgProxy);

// Usage
// <base-el @click=${this.doStuff}></base-el>